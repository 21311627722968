<template>
  <div data-cy="feed-page">
    <div
      class="mb-6 flex items-center gap-x-4"
      :class="{
        'flex-row-reverse justify-end': $route.path === '/feed/settings' && canFetchFeed,
        'justify-between': $route.path !== '/feed/settings',
      }"
    >
      <h1 class="flex items-center gap-x-2">
        {{ $route.path === '/feed' ? 'Engagement Builder' : 'Settings' }}
        <tooltip v-if="$route.path === '/feed'" content="Shortcuts">
          <div @click="isShortcutsModalVisible = true" class="block cursor-pointer">
            <inline-svg src="/img/icons/info.svg" class="h-5 w-5 fill-gray" />
          </div>
        </tooltip>
      </h1>
      <div v-if="$route.path === '/feed'" class="flex items-center gap-x-4">
        <new-drop-down
          data-cy="feed-filters-button"
          buttonType="secondary"
          :buttonSize="isSmallScreen ? 'lg' : 'md'"
          :icon="isSmallScreen"
        >
          <template #button>
            <inline-svg src="/img/icons/filter.svg" class="h-6 w-6 md:mr-2" />
            <span class="hidden sm:inline"> Filters </span>
          </template>
          <template>
            <div class="space-y-2">
              <new-base-checkbox
                data-cy="search-terms"
                inputValue="search-terms"
                label="Search terms"
                v-model="tweetsToShow"
              />
              <new-base-checkbox
                data-cy="watched-users"
                inputValue="watched-users"
                label="Watched users"
                v-model="tweetsToShow"
              />
            </div>
          </template>
        </new-drop-down>
        <router-link data-cy="feed-settings-button" to="/feed/settings">
          <new-button icon size="lg" type="secondary">
            <inline-svg src="/img/icons/settings.svg" class="h-6 w-6 stroke-white" />
          </new-button>
        </router-link>
      </div>
      <div v-else>
        <router-link
          data-cy="feed-settings-back"
          v-if="canFetchFeed"
          to="/feed"
          class="button small-button"
        >
          <inline-svg src="/img/icons/back.svg" class="" />
        </router-link>
      </div>
    </div>
    <div v-if="$route.path === '/feed/settings'" class="space-y-4">
      <div
        class="dark-mode-bg dark-mode-border-0 border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
      >
        <h2 class="mb-5 text-2xl font-bold">Watched users</h2>
        <div>
          <div class="ml-0">
            <ul class="list-disc" data-cy="feed-users-block">
              <li class="text-lg">
                Add the username of at least 1 Twitter user you want to interact with.
              </li>
              <li class="text-lg">
                Engage with them often to build your network and grow on Twitter.
              </li>
              <li v-if="maxAllowedWatchedUsers === Infinity" class="text-lg">
                As a {{ userPlan }} user, you can add an unlimited number of users.
              </li>
              <li v-else class="text-lg">
                As a {{ userPlan }} user, you can watch up to {{ maxAllowedWatchedUsers }}
                {{ maxAllowedWatchedUsers === 1 ? 'user' : 'users' }}.
                <template v-if="nextPlansLimitsMessage('watchedUsers')">
                  <br />

                  <a
                    href="javascript:;"
                    class="text-main-color-100"
                    @click="openFeedLimitNextPlanPopUp('watchedUsers')"
                    data-cy="feed-users-upgrade-link"
                    >Upgrade your subscription</a
                  >
                  {{ nextPlansLimitsMessage('watchedUsers') }}
                </template>
              </li>
            </ul>
          </div>
          <div>
            <ul class="w-60 ml-0 list-none">
              <li class="my-2 mr-2" v-for="(_, i) in watchedUsers" v-bind:key="i">
                <base-input
                  type="text"
                  v-model="watchedUsers[i]"
                  :disabled="isSavingFeedSettings"
                  :appendIcon="watchedUsers.length > 1 ? `/img/icons/close-circle.svg` : null"
                  @append-icon-click="removeWatchedUser(i)"
                  @blur="watchedUserBlur(i)"
                  placeholder="Please type a Twitter username"
                  data-cy="feed-username-input"
                />
              </li>
            </ul>
            <tooltip v-if="watchedUsers.length < maxAllowedWatchedUsers" content="Add a new user">
              <new-button
                @click="addWatchedUserField"
                data-cy="feed-add-user-button"
                icon
                size="lg"
              >
                <inline-svg src="/img/icons/add.svg"></inline-svg>
              </new-button>
            </tooltip>
          </div>
        </div>
      </div>
      <div
        class="dark-mode-bg dark-mode-border-0 border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
      >
        <div>
          <h2 class="mb-5">Search</h2>
          <div>
            <div class="ml-0">
              <ul class="list-disc" data-cy="feed-search-block">
                <li class="text-lg">
                  Keep up to date with the latest tweets from your Twitter search.
                </li>

                <li v-if="maxAllowedWatchedSearchTerms === Infinity" class="text-lg">
                  As a {{ userPlan }} user, you can watch an unlimited number of search terms.
                </li>
                <li v-else class="text-lg">
                  As a {{ userPlan }} user, you can watch up to
                  {{ maxAllowedWatchedSearchTerms }}
                  search {{ maxAllowedWatchedSearchTerms === 1 ? 'term' : 'terms' }}.
                  <template v-if="nextPlansLimitsMessage('watchedSearchTerms')">
                    <br />

                    <a
                      href="javascript:;"
                      class="text-main-color-100"
                      @click="openFeedLimitNextPlanPopUp('watchedSearchTerms')"
                      data-cy="feed-search-upgrade-link"
                      >Upgrade your subscription</a
                    >
                    {{ nextPlansLimitsMessage('watchedSearchTerms') }}
                  </template>
                </li>
              </ul>
            </div>
            <div>
              <ul class="w-60 ml-0 list-none">
                <li
                  class="my-2 mr-2"
                  v-for="(settings, i) in searchSettings"
                  :key="`search-settings-${i}`"
                >
                  <div
                    class="advanced-search-settings-container"
                    @click="editAdvancedSettings(i, settings)"
                  >
                    {{ formatAdvancedSearchInputValue(settings) }}
                    <span>
                      <inline-svg
                        src="/img/icons/close-circle.svg"
                        class="absolute right-2 top-2"
                        @click.stop="deleteAndSaveAdvancedSearchSetting(i)"
                      />
                    </span>
                  </div>
                </li>
              </ul>
              <div v-if="canAddSearchSettings">
                <tooltip content="Add a search term">
                  <new-button
                    @click="openTwitterAdvancedSearchModal"
                    icon
                    size="lg"
                    data-cy="feed-add-search-button"
                  >
                    <inline-svg src="/img/icons/add.svg"></inline-svg>
                  </new-button>
                </tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="dark-mode-bg dark-mode-border-0 border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
      >
        <div class="w-100">
          <h3 class="mb-5 text-xl font-bold">Import users</h3>
          <ul class="list-disc">
            <li class="text-lg">Import users from your Twitter lists.</li>
            <li class="text-lg">Only lists with users are taken into account.</li>
          </ul>
          <template v-if="numberOfWatchedUsers >= maxAllowedWatchedUsers">
            <tooltip content="Your Watched users list is full">
              <span
                class="border border-gray-700 block cursor-not-allowed rounded-lg p-2 text-sm text-dark-mode-15"
                >Choose a Twitter list</span
              >
            </tooltip>
          </template>
          <template v-else>
            <select
              @change="openTwitterListMembersModal"
              v-model="selectedList"
              class="border border-gray-700 block cursor-pointer rounded-lg text-sm"
              data-cy="feed-choose-list"
            >
              <option :value="{}" selected disabled>
                {{
                  isFetchingTwitterLists ? 'Loading your Twitter lists...' : 'Choose a Twitter list'
                }}
              </option>
              <option v-for="list in twitterLists" v-bind:key="list.id" :value="list">
                {{ list.name }}
              </option>
            </select>
          </template>
        </div>
      </div>
    </div>
    <div v-else>
      <div data-cy="feed-loader" class="loader" v-if="isFetchingFeed">
        <span class="loading loading-lg"
          ><inline-svg src="/img/icons/loading.svg"></inline-svg
        ></span>
      </div>
      <template class="space-y-6" v-else>
        <new-alert v-if="areWatchedUsersMoreThanUserLimit">
          You have more than {{ maxAllowedWatchedUsers }} watched users. The others will be ignored
          unless you
          <a
            href="javascript:;"
            class="text-main-color-100"
            @click="openFeedLimitNextPlanPopUp('watchedUsers')"
            >upgrade your subscription</a
          >.
        </new-alert>

        <new-alert v-if="areWatchedSearchTermsMoreThanUserLimit">
          You have more than {{ maxAllowedWatchedSearchTerms }} search terms. The others will be
          ignored unless you
          <a
            href="javascript:;"
            class="text-main-color-100"
            @click="openFeedLimitNextPlanPopUp('watchedSearchTerms')"
            >upgrade your subscription</a
          >.
        </new-alert>

        <new-alert v-if="userNotFoundInWatchedList">
          The following account(s) were either deleted or got suspended.<br />
          <ul>
            <li v-for="user in userProfile.feedSettings.usersNotFound" :key="user.twitterId">
              @{{ user.username }}
            </li>
          </ul>
        </new-alert>
        <virtual-list
          :data-key="'id'"
          :data-sources="tweetsByDate"
          :data-component="feedContainer"
          :page-mode="true"
          :estimate-size="520"
        />
        <p v-if="tweets.length === 0">
          No feed to show.<br /><br />
          Please try updating the watched users from the
          <router-link to="/feed/settings">Engagement Builder settings</router-link>.
        </p>
      </template>
    </div>
    <back-to-top-custom />
    <feed-and-mentions-shortcuts-modal
      v-if="isShortcutsModalVisible"
      @close="isShortcutsModalVisible = false"
    />

    <twitter-list-members-modal
      v-if="isTwitterListModalVisible"
      :list="selectedList"
      :maxAllowedWatchedUsers="maxAllowedWatchedUsersFromList"
      @close="closeTwitterListMembersModal"
      v-on:send-members-list="setWatchedUsersFromTwitterList"
      v-on:reset-twitter-list="resetTwitterMembersList"
    />
    <advanced-tweet-search-modal
      v-if="isAdvancedTweetSearchModalOpened"
      :advancedSettingsToEdit="advancedSettingsToEdit"
      @close="closeTwitterAdvancedSearchModal"
      @create="createAndSaveAdvancedSearchSettings"
      @update="updateAndSaveAdvancedSearchSettings"
    />

    <upgrade-to-next-plan-pop-up
      :show="showNextPlanPopUp"
      :content="nextPlanPopUpContent"
      @close="showNextPlanPopUp = false"
      :nextPlan="nextPlan"
    />
  </div>
</template>

<script>
  import BackToTopCustom from '@/views/Widgets/BackToTopCustom';
  import controller from '@/controller';
  import FeedItemContainer from '@/components/FeedItemContainer';
  import FeedAndMentionsMixin from './Mixins/FeedAndMentionsMixin.vue';
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import SwalModalMixin from './Mixins/SwalModalMixin.vue';
  import TwitterListMembersModal from '@/views/Modals/TwitterListMembersModal.vue';
  import FeedAndMentionsShortcutsModal from '@/views/Modals/FeedAndMentionsShortcutsModal.vue';
  import AdvancedTweetsSearchMixin from './Mixins/AdvancedTweetSearchMixin.vue';
  import AdvancedTweetSearchModal from '@/views/Modals/AdvancedTweetSearchModal.vue';
  import NewDropDown from '@/components/NewDropDown.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import UpgradeToNextPlanPopUp from '@/components/UpgradeToNextPlanPopUp.vue';
  import UpgradeToNextPlanMixin from './Mixins/UpgradeToNextPlanMixin.vue';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';
  import { v4 as uuid } from 'uuid';
  import VirtualList from 'vue-virtual-scroll-list';
  import { EventBus } from '@/event-bus';

  export default {
    name: 'new-feed-page',
    data() {
      return {
        tweetsToShow: ['watched-users'],
        isFeedActions: false,
        isShortcutsModalVisible: false,
        isWatching: false,
        tweets: [],
        isTwitterListModalVisible: false,
        searchFeedTweets: [],
        isFetchingFeed: false,
        isFetchingTwitterLists: false,
        twitterLists: [],
        selectedList: {},
        maxAllowedWatchedUsersPerPlan: {
          premium: 100,
          standard: 15,
          none: 5,
        },
        maxAllowedWatchedSearchTermsPerPlan: {
          premium: 10,
          standard: 5,
          none: 1,
        },
        feedContainer: FeedItemContainer,
      };
    },
    mounted() {
      this.tweetsToShow = JSON.parse(localStorage.getItem('tweetsToShow')) || ['watched-users'];
      this.fetchUserLists();
      this.watchedUsers = lodash
        .get(this.userProfile, 'feedSettings.watchedUsers', [{ username: '' }])
        .map((user) => user.username);

      this.searchSettings = lodash.get(this.userProfile, 'feedSettings.searchSettings', []);

      const { path } = this.$route;
      if (
        (path === '/feed' && !this.canFetchFeed) ||
        (path === '/feed/search' && !this.canFetchSearchFeed)
      ) {
        this.$router.push('/feed/settings');
      }

      if (this.canFetchFeed) {
        this.fetchFeed();
      }

      if (this.canFetchSearchFeed) {
        this.fetchSearchFeed();
      }

      EventBus.$on('hide-tweet', (tweetToHide) => {
        if (tweetToHide.type === 'search_terms_feed') {
          this.searchFeedTweets = this.searchFeedTweets.filter(
            (tweet) => tweet.id !== tweetToHide.id,
          );
        } else {
          this.tweets = this.tweets.filter((tweet) => tweet.id !== tweetToHide.id);
        }
      });
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile', currentUser: 'getCurrentUser' }),
      canAddSearchSettings() {
        return this.searchSettings.length < this.maxAllowedWatchedSearchTerms;
      },
      numberOfWatchedUsers() {
        return [...this.watchedUsers].filter((username) => username).length;
      },
      areWatchedUsersMoreThanUserLimit() {
        return this.watchedUsers.length > this.maxAllowedWatchedUsers;
      },
      areWatchedSearchTermsMoreThanUserLimit() {
        return this.watchedSearchTerms.length > this.maxAllowedWatchedSearchTerms;
      },
      maxAllowedWatchedUsers() {
        return this.PLANS[this.userProfile.customerStatus].limits.feed.watchedUsers;
      },
      maxAllowedWatchedSearchTerms() {
        return this.PLANS[this.userProfile.customerStatus].limits.feed.watchedSearchTerms;
      },
      saveTooltipContent() {
        if (this.watchedUsers.filter((n) => n).length < 2) {
          return 'Add some users to watch';
        } else if (!this.isWatchedUsersValid) {
          return 'Remove duplicates from your watched users';
        } else if (this.searchSettings.length < 2) {
          return 'Add some search terms';
        } else {
          return 'Click to save';
        }
      },
      canFetchFeed() {
        return lodash.get(this.userProfile, 'feedSettings.watchedUsers', []).length >= 2;
      },
      canFetchSearchFeed() {
        return lodash.get(this.userProfile, 'feedSettings.searchSettings', []).length >= 1;
      },
      canSaveFeedSettings() {
        return this.watchedUsers.filter((n) => n).length >= 2 && this.isWatchedUsersValid;
      },
      isWatchedUsersValid() {
        return (
          new Set(this.watchedUsers.map((username) => username.trim().toLowerCase())).size ===
          this.watchedUsers.length
        );
      },
      searchTweetsByDate() {
        return lodash.groupBy(this.searchFeedTweets, (tweet) =>
          moment(tweet.createdAt).format('YYYY-MM-DD'),
        );
      },
      tweetsByDate() {
        let filteredTweets = [];

        if (this.tweetsToShow.length === 0) {
          filteredTweets = [];
        } else if (this.tweetsToShow.includes('watched-users') && this.tweetsToShow.length === 1) {
          filteredTweets = lodash.groupBy(this.tweets, (tweet) =>
            moment(tweet.createdAt).format('YYYY-MM-DD'),
          );
        } else if (this.tweetsToShow.includes('search-terms') && this.tweetsToShow.length === 1) {
          filteredTweets = lodash.groupBy(this.searchFeedTweets, (tweet) =>
            moment(tweet.createdAt).format('YYYY-MM-DD'),
          );
        } else {
          const tweets = [...this.tweets, ...this.searchFeedTweets].sort((a, b) => {
            if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
              return 1;
            }
            if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
              return -1;
            }
            return 0;
          });
          filteredTweets = lodash.groupBy(tweets, (tweet) =>
            moment(tweet.createdAt).format('YYYY-MM-DD'),
          );
        }

        return this.flattenTweetsPerDay(filteredTweets);
      },
      userNotFoundInWatchedList() {
        return (
          this.userProfile.feedSettings &&
          this.userProfile.feedSettings.usersNotFound &&
          this.userProfile.feedSettings.usersNotFound.length > 0
        );
      },
    },
    watch: {
      tweetsToShow() {
        localStorage.setItem('tweetsToShow', JSON.stringify(this.tweetsToShow));
      },
      '$route.path': {
        handler: function (path) {
          if (path === '/feed' && this.isFeedSettingsUpdated) {
            this.isFeedSettingsUpdated = false;
            this.fetchFeed();
            this.fetchSearchFeed();
          }
        },
      },
    },
    methods: {
      nextPlansLimitsMessage(fieldName) {
        const plansAboveCurrentPlan = this.getPlansAboveCurrentPlan(
          this.userProfile.customerStatus,
        );
        if (plansAboveCurrentPlan.length === 0) {
          return '';
        } else {
          return `to increase the limit to ${plansAboveCurrentPlan
            .map(
              (plan) =>
                `${
                  plan.limits.feed[fieldName] === Infinity
                    ? 'unlimited'
                    : plan.limits.feed[fieldName]
                } (${plan.label} plan)`,
            )
            .join(' or ')}.`;
        }
      },
      async updateAndSaveAdvancedSearchSettings(index, settings) {
        this.updateAdvancedSearchSettings(index, settings);
        await this.saveFeedSettings();
      },
      async createAndSaveAdvancedSearchSettings(settings) {
        this.createAdvancedSearchSettings(settings);
        await this.saveFeedSettings();
      },
      async deleteAndSaveAdvancedSearchSetting(i) {
        this.deleteAdvancedSearchSettings(i);
        await this.saveFeedSettings();
      },
      async watchedUserBlur(i) {
        if (this.watchedUsers[i]) {
          await this.saveFeedSettings();
        }
      },
      openTwitterListMembersModal() {
        this.isTwitterListModalVisible = true;
        this.$eventStore.engagementBuilder.settings('Import users');
      },
      closeTwitterListMembersModal() {
        this.isTwitterListModalVisible = false;
      },
      resetTwitterMembersList() {
        this.selectedList = {};
      },
      async setWatchedUsersFromTwitterList(members) {
        this.resetTwitterMembersList();
        const watchedUsers = this.watchedUsers
          .filter((username) => username)
          .map((username) => username.toLowerCase());
        const res = [...watchedUsers, ...members.map((m) => m.username.toLowerCase())];
        this.watchedUsers = Array.from(new Set(res));
        await this.saveFeedSettings();
      },
      showFeedActions() {
        this.isFeedActions = true;
      },
      showWatching() {
        this.isWatching = true;
      },
      addWatchedUserField() {
        this.watchedUsers.push('');
        this.$eventStore.engagementBuilder.settings('Add user');
      },
      async fetchUserLists() {
        try {
          this.isFetchingTwitterLists = true;
          this.twitterLists = await controller.twitter.getTwitterLists(
            this.currentUser,
            this.userProfile.uid,
          );
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Error',
            html: 'An error occurred while trying to fetch your Twitter lists.',
            type: 'warning',
          });
        } finally {
          this.isFetchingTwitterLists = false;
        }
      },
      async fetchSearchFeed() {
        this.isFetchingFeed = true;
        try {
          const fetchedSearchFeed = await controller.twitter.searchTweets(
            this.currentUser,
            this.userProfile.uid,
          );
          const feedInteractions = lodash.get(
            this.userProfile,
            'integrations.twitter.searchFeedInteractions',
            [],
          );
          const authorIds = fetchedSearchFeed.map((tweet) => tweet.user.id);
          const { data: followedAuthorIds } = await controller.twitter.getFollowingsByAuthorIds(
            authorIds,
            this.currentUser,
            this.userProfile.uid,
          );
          const searchFeedResult = fetchedSearchFeed.reduce((result, tweet) => {
            if (followedAuthorIds.includes(tweet.user.id)) {
              return [tweet, ...result];
            }
            return [...result, tweet];
          }, []);
          this.searchFeedTweets = searchFeedResult
            .filter((tweet) => !(feedInteractions[tweet.id] && feedInteractions[tweet.id].isHidden))
            .map((tweet) => {
              return { ...tweet, type: 'search_terms_feed' };
            });
        } catch (error) {
          this.isFetchingFeed = false;
          this.swalModal({
            title: 'Error',
            html: 'An error occurred while fetching your feed.',
            type: 'warning',
          });
        }
        this.isFetchingFeed = false;
      },
      async fetchFeed() {
        this.isFetchingFeed = true;
        try {
          const fetchedFeed = await controller.twitter.fetchFeed(
            this.currentUser,
            this.userProfile.uid,
          );
          const feedInteractions = lodash.get(
            this.userProfile,
            'integrations.twitter.feedInteractions',
            [],
          );
          this.tweets = fetchedFeed
            .filter((tweet) => !(feedInteractions[tweet.id] && feedInteractions[tweet.id].isHidden))
            .map((tweet) => {
              return { ...tweet, type: 'watched_users_feed' };
            });
        } catch (error) {
          this.isFetchingFeed = false;
          this.swalModal({
            title: 'Error',
            html: 'An error occurred while fetching your feed.',
            type: 'warning',
          });
        }
        this.isFetchingFeed = false;
      },
      async removeWatchedUser(i) {
        this.watchedUsers.splice(i, 1);
        await this.saveFeedSettings();
      },
      async saveFeedSettings() {
        try {
          await this.updateFeedSettings();
        } catch (e) {
          return;
        }
      },
      flattenTweetsPerDay(tweetPerDay) {
        const result = [];

        for (const date in tweetPerDay) {
          result.push({ id: uuid(), isDate: true, date: date });
          result.push(...tweetPerDay[date]);
        }

        return result;
      },
    },
    components: {
      BackToTopCustom,
      // eslint-disable-next-line vue/no-unused-components
      FeedItemContainer,
      TwitterListMembersModal,
      FeedAndMentionsShortcutsModal,
      AdvancedTweetSearchModal,
      NewDropDown,
      UpgradeToNextPlanPopUp,
      VirtualList,
    },
    mixins: [
      FeedAndMentionsMixin,
      SwalModalMixin,
      AdvancedTweetsSearchMixin,
      BreakpointsMixin,
      CustomerStatusMixin,
      UpgradeToNextPlanMixin,
    ],
    beforeDestroy() {
      EventBus.$off('hide-tweet');
    },
  };
</script>
<style lang="scss" scoped>
  .advanced-search-settings-container {
    width: 100%;
    color: var(--color-primary);
    position: relative;
    border-width: 1px;
    border-color: var(--input-border);
    height: 2.5rem;
    padding-inline: 0.75rem;
    padding-block: 0.5rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 0.375rem;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
  }
</style>
